import { Component, Inject, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { CipoFieldTypes, CipoListControl } from 'src/app/shared/components/fields/common';
import { AssignContractUserRole, SelectUsersParameters } from './assign-users-dialog.model';
import { AssignmentsService } from '../assignments.service';
import { NotificationService } from 'src/app/shared/services';
import { CipoDialogModule } from 'src/app/shared/modules';
import { SearchableListboxComponent } from 'src/app/shared/components/searchable-listbox/searchable-listbox.component';
import { SearchableListBoxConfig, SearchableListItemType } from 'src/app/shared/components/searchable-listbox';

@Component({
  selector: 'cipo-assign-users-dialog',
  standalone: true,
  imports: [MatExpansionModule, CipoDialogModule, SearchableListboxComponent],
  providers: [AssignmentsService],
  templateUrl: './assign-users-dialog.component.html',
})
export class AssignUsersDialogComponent {
  translate = inject(TranslateService);
  assignmentsService = inject(AssignmentsService);
  notification = inject(NotificationService);

  saving = signal(false);
  entityInstanceId: number;
  existingUsers: number[] = [];
  listBoxConfig = signal<SearchableListBoxConfig>({
    columns: 3,
    multiple: true,
    searchable: true,
    noFoundMessage: "assignments.notFound"
  });
  listBoxOptions = signal<SearchableListItemType[]>(null);
  listBoxValues = signal<number[]>([]);

  constructor(
    public dialogRef: MatDialogRef<AssignUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectUsersParameters,
  ) {
    this.entityInstanceId = data.entityInstanceId;
    this.existingUsers = data.existingUsers;

    this.loadUsers();
  }

  loadUsers() {
    this.listBoxOptions.set(null);
    this.listBoxValues.set([]);
    this.assignmentsService.getUsers(this.entityInstanceId, false, this.existingUsers).subscribe(list => {
      this.listBoxOptions.set(list);
    });
  }

  returnUsers() {
    this.saving.set(true);
    const userIds = this.listBoxValues();
    const assignUsersModel: AssignContractUserRole = {
      id: this.entityInstanceId,
      userIds: [...this.existingUsers, ...userIds],
    };
    this.assignmentsService.asignUsers(assignUsersModel).subscribe({
      complete: () => {
        this.notification.success('assignments.successfully');
        this.saving.set(false);
        this.dialogRef.close(userIds);
      },
      error: () => {
        this.saving.set(false);
      },
    });
  }

  closeDialog() {
    this.dialogRef.close([]);
  }
}
