import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionTypeEnum } from '../../../../models/action';
import { EITransactionInstanceModel } from '../../../../models/module/entityInstance';
import { ActivityItemService } from './activity-item.service';

@Component({
  selector: 'app-entity-instance-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.scss'],
})
export class ActivityItemComponent {
  @Input('moduleid') moduleId: number;
  @Input('contractid') contractId: number;
  @Input() data: EITransactionInstanceModel;
  @Input() expanded: boolean;

  @Output('dataloaded') dataLoaded = new EventEmitter<EITransactionInstanceModel>();

  loaded = false;

  constructor(
    private activityItemService: ActivityItemService,
    private translate: TranslateService,
  ) {
    translate.getTranslation;
  }

  get actionNameByType(): string {
    switch (this.data.type) {
      case ActionTypeEnum.Comment:
        return this.translate.instant('MODULE_INSTANCE_ACTIVITY.ACTION_ADDED_COMMENT');
      case ActionTypeEnum.SaveInstanceFromCorrespondence:
        return this.translate.instant('MODULE_INSTANCE_ACTIVITY.ACTION_FROM_EMAIL');
      case ActionTypeEnum.Assignment:
        return this.translate.instant('MODULE_INSTANCE_ACTIVITY.ACTION_CHANGED_ASSIGNMENT');
      case ActionTypeEnum.SaveNotificationReplayFromCorrespondence:
        return this.translate.instant('MODULE_INSTANCE_ACTIVITY.ACTION_FROM_EMAIL_RESPONSE');
      case ActionTypeEnum.UpdateDocument:
        return this.translate.instant('MODULE_INSTANCE_ACTIVITY.ACTION_UPDATED_DOCUMENT');
      case ActionTypeEnum.Email:
        return this.translate.instant('MODULE_INSTANCE_ACTIVITY.ACTION_EMAIL');
      default:
        return this.data.transitionName || this.data.actionName;
    }
  }

  get actionNameEmail(): string | undefined {
    return this.data.type == ActionTypeEnum.SaveInstanceFromCorrespondence ? this.data.userEmail : undefined;
  }

  get showStates(): boolean {
    return (
      (this.data.type == ActionTypeEnum.WorkflowTransitions ||
        this.data.type == ActionTypeEnum.SaveInstanceFromCorrespondence ||
        this.data.type == ActionTypeEnum.Assignment) &&
      (!!this.data.state || !!this.data.nextState)
    );
  }

  itemOpened(): void {
    if (this.loaded) {
      return;
    }

    this.getItem();
  }

  getItem(): void {
    this.activityItemService
      .getActivityItem(this.moduleId, this.data.actionInstanceId, this.contractId, !!this.data.isDraftState)
      .subscribe(x => {
        this.dataLoaded.emit(x);
        this.loaded = true;
      });
  }
}
