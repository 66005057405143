<div fxLayout="column" class="full-height">
    <div>
        @if (config().searchable) {
            <div fxLayoutAlign="space-between center" class="m-b-md">
                <ng-content></ng-content>
                <span></span>
                <mat-form-field class="cipo-input hide-subscript p-0">
                    <mat-label>{{ "general.search" | translate }}</mat-label>
                    <input matInput [formControl]="searchControl" />
                    @if (searchControl.value) {
                        <button matSuffix [title]="'general.clear' | translate" mat-icon-button (click)="clearInput()">
                            <mat-icon>close</mat-icon>
                        </button>
                    } @else {
                        <mat-icon matSuffix>search</mat-icon>
                    }
                </mat-form-field>
            </div>
        }
        @if (config().noFoundMessage && filteredOptions() && !filteredOptions().length) {
            <span fxLayoutAlign="center start" class="m-t-lg">{{ config().noFoundMessage | translate }}</span>
        }
    </div>
    <div fxFlex class="list-content">
        @if (loading()) {
            @for (item of skeleton(); track $index) {
                <div>
                    @for (column of skeletonColumns(); track $index) {
                        <ngx-skeleton-loader
                            [theme]="{
                                width: 100 / config().columns - 5 + '%',
                                height: '40px',
                                'border-radius': '15px',
                                marginRight: '10px',
                                marginBottom: '10px',
                            }" />
                    }
                </div>
            }
        } @else {
            <mat-chip-listbox
                class="mat-mdc-chip-set-stacked"
                (change)="selectionChanged($event)"
                fxLayout="row wrap"
                [multiple]="config().multiple">
                @for (item of filteredOptions(); track item.key) {
                    <mat-chip-option
                        [fxFlex.gt-sm]="100 / config().columns - 2"
                        [fxFlex.lt-md]="100"
                        [value]="item.key"
                        [disabled]="item.disabled"
                        [title]="item.value | translate"
                        [selected]="values()?.includes(item.key)"
                        togglePosition="before">
                        <div fxLayout fxLayoutAlign="start center">
                            @if (item.prefixIcon) {
                                <mat-icon fxFlex="none" class="m-r-sm">{{ item.prefixIcon }}</mat-icon>
                            }
                            <span fxFlex="grow">{{ item.value | translate }}</span>
                        </div>
                    </mat-chip-option>
                }
            </mat-chip-listbox>
        }
    </div>
</div>
