import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, effect } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { TablePaginator, TableRow } from '../common';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../../shared/consts';
import { SharedComponentsModule } from '../../../modules/shared-components.module';
import { DataListBase } from '../data-list.base';
import { TableCellComponent } from '../table-cell/table-cell.component';

@Component({
  selector: 'cipo-paged-data-list',
  templateUrl: './paged-data-list.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedComponentsModule, TableCellComponent],
})
export class PagedDataListComponent<T = TableRow> extends DataListBase<T> implements AfterViewInit {
  dataSource: MatTableDataSource<T> = new MatTableDataSource();
  serverPagination: TablePaginator;

  constructor() {
    super();
    effect(() => {
      const options = this.options();
      if (options.serverPaginationSort && options.pagination) {
        this.setServerPaginatorOptions(options.pagination);
      }
    });

    effect(() => this.loading() && this.populateSkeleton());

    effect(
      () => {
        const rows = this.tableData();
        if (!rows) {
          this.loading.set(true);
          return;
        }
        if (!this.dataSource?.data) {
          this.dataSource = new MatTableDataSource(rows);
        } else {
          this.dataSource.data = rows;
        }
        this.checkLeftRightIcons(rows);
        this.loading.set(false);
      },
      { allowSignalWrites: true },
    );
  }

  @ViewChild('clientPaginator') paginator: MatPaginator;

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    const options = this.options();
    if (options.pagination) {
      if (options.serverPaginationSort) {
        this.setServerPaginatorOptions(options.pagination);
      } else {
        const { pageSize, pageSizeOptions } = options.pagination;
        this.paginator.pageSize = pageSize;
        this.paginator.pageSizeOptions = pageSizeOptions;
        this.dataSource.paginator = this.paginator;
      }
    }
  }

  setServerPaginatorOptions({ length, pageIndex, pageSize, pageSizeOptions }: TablePaginator) {
    this.serverPagination = {
      length: length ?? PAGE_SIZE_OPTIONS.default[1],
      pageIndex: pageIndex ?? 0,
      pageSize: pageSize ?? PAGE_SIZE_OPTIONS.default[1],
      pageSizeOptions: pageSizeOptions ?? PAGE_SIZE_OPTIONS.default,
    };
  }

  tableSortChanged(event: Sort) {
    if (this.options().serverPaginationSort) {
      this.loading.set(true);
      this.sortChanged.emit(event);
    }
  }

  paginationChange(event: TablePaginator) {
    this.loading.set(true);
    this.paginationUpdated.emit(event);
  }

  isAllSelected() {
    const numSelected = this.selection().selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection().clear();
      return;
    }
    this.selection().select(...this.dataSource.data);
  }

  populateSkeleton() {
    if (this.dataSource.data.length) {
      this.dataSource.data = this.dataSource.data.map(row => ({ ...row, empty: true }));
    } else {
      const pageSize = this.options()?.pagination?.pageSize ?? DEFAULT_PAGE_SIZE;
      const data: T[] = [];
      for (let i = 0; i < pageSize; i++) {
        data.push({ empty: true } as T);
      }
      this.dataSource.data = data;
    }
  }
}
