<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ "assignments.selectUsers" | translate }}</p>
    <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="p-t-md full-height">
        <cipo-searchable-listbox [(values)]="listBoxValues" [config]="listBoxConfig()" [options]="listBoxOptions()">
            <div fxLayoutGap="4px" fxLayoutAlign="start start" class="small-info-text m-b-md">
                <mat-icon fxFlex="none" class="cipo-icon cipo-icon--sm material-symbols-outlined m-t-sm">info</mat-icon>
                <span fxLayout="row wrap" class="m-t-xs">{{ "assignments.cascadeInfo" | translate }}</span>
            </div>
        </cipo-searchable-listbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog()" [disabled]="saving()">{{ "general.close" | translate }}</button>
    <button mat-raised-button color="primary" (click)="returnUsers()" [disabled]="saving()">
        {{ "general.save" | translate }}
    </button>
</mat-dialog-actions>
