import { Component, Inject, Input, OnInit, Output, effect, inject, signal } from '@angular/core';
import { PeopleModel } from '../permissions/permissions.model';
import { PermissionsService } from '../permissions/permissions.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SelectPeopleParameters } from './select-people-dialog.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Permission } from 'src/app/models/permission';
import { SearchableListboxComponent } from 'src/app/shared/components/searchable-listbox/searchable-listbox.component';
import { SearchableListBoxConfig, SearchableListItemType } from 'src/app/shared/components/searchable-listbox';

const keySeparator = '_';

@Component({
  selector: 'app-select-people-dialog',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatButtonToggleModule,
    SearchableListboxComponent,
  ],
  providers: [PermissionsService],
  templateUrl: './select-people-dialog.component.html',
})
export class SelectPeopleDialogComponent {
  permissionsService = inject(PermissionsService);
  translate = inject(TranslateService);

  notContractModule: boolean = false;
  folderId: number;
  fileId: number;
  fileParentFolderId: number;
  entityInstanceId: number;
  allow = true;
  listBoxConfig = signal<SearchableListBoxConfig>({
    columns: 3,
    multiple: true,
    searchable: true,
    noFoundMessage: "fileFolderPermissions.notFound"
  });
  listBoxOptions = signal<SearchableListItemType<string>[]>(null);
  listBoxValues = signal<string[]>([]);

  constructor(
    public dialogRef: MatDialogRef<SelectPeopleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectPeopleParameters,
  ) {
    this.folderId = data.folderId;
    this.fileId = data.fileId;
    this.fileParentFolderId = data.fileParentFolderId;
    this.entityInstanceId = data.entityInstanceId;
    this.notContractModule = data.notContractModule;

    this.loadAvailablePeople(this.allow);
  }

  loadAvailablePeople(allow: boolean) {
    this.listBoxOptions.set(null);
    this.listBoxValues.set([]);
    this.permissionsService
      .getAvailableUserRoles(this.folderId, this.fileId, this.fileParentFolderId, this.entityInstanceId, allow)
      .subscribe(list => {
        const options = list.map(l => ({
          key: `${l.id}${keySeparator}${l.isUser}`,
          value: l.name,
          prefixIcon: l.isUser ? 'person' : 'groups',
        }));
        this.listBoxOptions.set(options);
      });
  }

  returnPeople() {
    const list: PeopleModel[] = this.listBoxValues()
      .map(l => l.split(keySeparator))
      .map(arr => ({
        id: Number(arr[0]),
        isUser: arr[1].toLowerCase() === 'true',
        isNew: true,
        name: this.listBoxOptions().find(p => p.key == arr.join(keySeparator))?.value ?? '',
        permissions: [
          {
            permissionId: Permission.Read,
            permission: Permission[Permission.Read],
            allow: !this.notContractModule && this.allow,
            deny: !this.allow,
          },
        ],
      }));
    this.dialogRef.close(list);
  }

  closeDialog() {
    this.dialogRef.close([]);
  }
}
