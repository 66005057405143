import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services';
import { FolderFilePermissionListModel, PeopleModel } from './permissions.model';
import { Observable, of } from 'rxjs';

@Injectable()
export class PermissionsService {
  constructor(private http: HttpService) {}

  getAvaialblePermissions(entityInstanceId: number): Observable<FolderFilePermissionListModel[]> {
    const params = this.http.buildHttpParams({ entityInstanceId });
    return this.http.get<FolderFilePermissionListModel[]>(`_api/ProjectFolderFile/GetAvaialblePermissions`, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  getFolderPermissions(folderId: number, entityInstanceId: number): Observable<PeopleModel[]> {
    const params = this.http.buildHttpParams({ entityInstanceId, folderId });
    return this.http.get<PeopleModel[]>(`_api/ProjectFolderFile/GetFolderPermissions`, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  getFilePermissions(fileId: number, entityInstanceId: number): Observable<PeopleModel[]> {
    const params = this.http.buildHttpParams({ entityInstanceId, fileId });
    return this.http.get<PeopleModel[]>(`_api/ProjectFolderFile/GetFilePermissions`, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  getAvailableUserRoles(folderId: number, fileId: number, fileParentFolderId: number, entityInstanceId: number, allow: boolean): Observable<PeopleModel[]> {
    const params = this.http.buildHttpParams({ entityInstanceId, folderId, fileId, fileParentFolderId, allow });
    return this.http.get<PeopleModel[]>(`_api/ProjectFolderFile/GetAvailableUserRoles`, {
      useLoader: false,
      httpOptions: { params },
    });
  }
  
  deleteUserRolePermissions(
    folderId: number,
    fileId: number,
    userId: number,
    roleId: number,
    entityInstanceId: number,
  ): Observable<void> {
    const params = this.http.buildHttpParams({ folderId, fileId, userId, roleId, entityInstanceId });
    return this.http.put<void>(`_api/ProjectFolderFile/DeleteUserRolePermissionsFolderFile`, null, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  syncFolderPermissions(people: PeopleModel[], folderId: number, entityInstanceId: number): Observable<void> {
    const params = this.http.buildHttpParams({ folderId, entityInstanceId });
    return this.http.put<void>(`_api/ProjectFolderFile/SyncUserRolePermissionsFolder`, people, {
      useLoader: false,
      httpOptions: { params }
    });
  }

  syncFilePermissions(people: PeopleModel[], fileId: number, entityInstanceId: number): Observable<void> {
    const params = this.http.buildHttpParams({ fileId, entityInstanceId });
    return this.http.put<void>(`_api/ProjectFolderFile/SyncUserRolePermissionsFile`, people, {
      useLoader: false,
      httpOptions: { params }
    });
  }
}
