import { cipo } from 'cipo';

cipo.factory("TransitionMod", function ($q, Model, URI, Form, Message, Dictionaries, ModuleDictionaries, MAX_NR_VISIBLE_SELECT_ITEMS, userService,
        STATES_STATUS_TYPES) {
    var TransitionMod = Model.extend(function (stateInfo, obj) {

        this.properties = {
            id: null,
            default: false,
            name: "",
            nextStateId: stateInfo.stateId,
            roleIds: [],
            roleToExcludeIds: [],
            stateId: stateInfo.stateId || null,
            screenId: null,
            isUsedInMultiple: false,
            nextStateName: "",
            hideRolesNotify: (stateInfo.stateId ?? 0) == 0
        }
        
        var self = this;
        self.editable = (obj || {}).id ? false : true;
        self.statesDict = [];
        self.workflowId = stateInfo.workflowId;   
        self.stateInfo = stateInfo;
        self.betweenOperatorId = 13;

        self.sources = ["allRoles", "states", "forms"];

        self.editMode = false;
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }
        ModuleDictionaries.getDicts(["states"]).then(function () {
            self.properties.nextStateName = (ModuleDictionaries.dataSources.states.lookup[self.properties.nextStateId] || {}).value || "";
            if (!self.properties.id) self.createForm();
        })
    });
    
    // the states where the transition is allowed to go, according to the parent state
    TransitionMod.prototype.filterStatesDict = function () {
        var self = this;
        var statesDictFiltered = [];
        for (var i = 0; i < ModuleDictionaries.dataSources.states.data.length; i++) {
            if (STATES_STATUS_TYPES[parseInt(self.stateInfo.stateType)].indexOf(parseInt(ModuleDictionaries.dataSources.states.data[i].type)) != -1) {
                statesDictFiltered.push(ModuleDictionaries.dataSources.states.data[i]);
            }
        }
        return statesDictFiltered;
    }

    TransitionMod.prototype.getRoles = function () {
        return ModuleDictionaries.dataSources.allRoles.data;
    }

    TransitionMod.prototype.getUsedRoles = function () {
        return (this.getRoles() || []).filter(r => r.isUsed);
    }

    TransitionMod.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.isConditions = false;
        self.form.initializing = true;
        var grid;
        
        ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
        ModuleDictionaries.getDicts(self.sources)
            .then(function () {
                var form = {
                    name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
                    roleIds: {
                        label: 'Roles', type: 'select', multiple: true,
                        options: self.getUsedRoles(), validation: { required: true }
                    },
                    isUsedInMultiple: { label: 'Can be used in multiple transition update', type: 'checkbox' },
                    hideRolesNotify: { label: 'Hide Roles to Notify', type: 'checkbox' }
                };
                if (!self.stateInfo.isStateDefault || self.stateInfo.moduleId == -3 || self.stateInfo.moduleId == -4) {
                    form.screenId = { label: 'Form', type: 'select', options: ModuleDictionaries.dataSources.forms.data };
                }
                if (self.properties.globalType != 1) form.nextStateId = {
                    label: 'Transition to state', type: 'select',
                    options: self.filterStatesDict(), validation: { required: true }
                };
                if (self.properties.default) form.nextStateId.editMode = false;
                if (self.stateInfo.isStateDefault) {
                    //form.screenId.validation = { required: true };                       
                    grid = [{ name: 25, nextStateId: 15, roleIds: 25, isUsedInMultiple: 20, hideRolesNotify: 15 }];
                    
                    if (self.stateInfo.moduleId == -3 || self.stateInfo.moduleId == -4) 
                        grid.push({ screenId: 50 });
                } else {
                    form.roleToExcludeIds = {
                        label: 'Roles to exclude', type: 'select', multiple: true,
                        options: self.getUsedRoles()
                    };                      
                    grid = [{ name: 50, nextStateId: 25, screenId: 25 }, { roleIds: 40, roleToExcludeIds: 20, isUsedInMultiple: 20, hideRolesNotify: 20 }];
                }
                if (self.properties.globalType == 1) grid = [{ name: 50, screenId: 50 }, { roleIds: 40, roleToExcludeIds: 20, isUsedInMultiple: 20, hideRolesNotify: 20 }];


                self.form.set_Description(form);

                Object.defineProperty(self, 'isScreenChanged', {
                    get: function () {
                        return self.properties.screenId != self.form.originalData.screenId
                            && self.properties.hasConditionalTransitions;
                    }
                });

                self.form.setTemplate('grid', grid);
                self.form.store_Data();
                // if (self.properties.id) self.form.editMode = false;
                self.form.initializing = false;
            })
    }

    TransitionMod.prototype.revertScreen = function () {
        this.properties.screenId = this.form.originalData.screenId;
    }

    TransitionMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var dataURL;
        if (!self.properties.isGlobal) {
            dataURL = self.properties.id ? URI.TRANSITION.EDIT : URI.TRANSITION.ADD;
        } else {
            dataURL = self.properties.id ? URI.TRANSITION.EDIT_GLOBAL : URI.TRANSITION.ADD_GLOBAL;
        }     
        
        self.form.loading = true;
        self.form.validate();
        //console.error("self.form.isValid", self.form.isValid);
        if (self.form.isValid) {
            
            self[dataURL.method](dataURL, { url: { workflowId: self.workflowId }, urltype: "obj", body: self.properties }, { headers: { moduleId: self.stateInfo.moduleId } })
                .then(function (r) {
                    Message.info('Transition saved successfully');
                    // if (!self.properties.id) self.properties.id = r;
                    self.isModified = true;
                    if (self.form.originalData.screenId != self.properties.screenId || !self.properties.screenId) {
                        self.properties.hasConditionalTransitions = false;
                        if (!self.properties.screenId) self.isConditions = false;
                    }
                    if (self.isConditions && self.form.originalData.screenId != self.properties.screenId && self.properties.screenId) {
                        self.conditions(true);
                    }

                    self.form.set_Data(self.properties);
                    self.form.store_Data(self.properties);

                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    if(self.form) self.form.loading = false;
                });
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        return p.promise;
    }

    TransitionMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting transition...";
        self.deleting = true;
        var dataURL = URI.TRANSITION.DELETE;

        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.stateInfo.moduleId } })
        .then(function (result) {
            Message.info('Transition deleted successfully');
            p.resolve();
        })
        .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete transition. Try again?";
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;
    }

    // conditions

    TransitionMod.prototype.deleteConditionDialog = function (item) {
        var self = this;
        item.deleting = true;
        self.isBusy = true;
    }

    TransitionMod.prototype.cancelDeleteConditionDialog = function (item) {
        var self = this;
        item.deleting = false;
        self.isBusy = false;
    }

    TransitionMod.prototype.conditions = function (mustLoad) {
        var self = this;
        self.isConditions = true;
        if (self.form.originalData.screenId == self.properties.screenId || mustLoad) {
            
            self.isConditionsLoaded = false;
            self.isBusy = true;
            self.conditionsList = [];
            var all = $q.all([self.get_conditions(), Dictionaries.ConditionalFields({ moduleId: self.stateInfo.moduleId }, { screenId: self.properties.screenId })]);
            all
                .then(function (r) {
                    self.fieldsTypes = { "1": [], "2": [], "3": [] };
                    
                    var conditions = r && r[0] || [];
                    var conditionalFields = r && r[1] || [];
                    conditionalFields = self.addMissingConditionalFields(conditions, conditionalFields);

                    if (conditionalFields.length) {
                        for (var i = 0; i < conditionalFields.length; i++) {
                            var key = conditionalFields[i].key;
                            var typeId = conditionalFields[i].typeId;
                            if (typeId == 2 || typeId == 5) {
                                self.fieldsTypes[2].push(key);
                            } else if (self.fieldsTypes[typeId]) {
                                self.fieldsTypes[typeId].push(key);
                            } else {
                                Message.error("Unknown type id " + typeId + ", please report this to Andrei Balan.");
                            }
                        }
                    }
                    self.conditionFieldsDict = conditionalFields;
                    if (conditions.length) {
                        for (var i = 0; i < conditions.length; i++) {
                            self.conditionsList.push(self.createConditionForm(conditions[i]));
                        }
                    } else {
                        self.properties.hasConditionalTransitions = false;
                    }
                    self.isConditionsLoaded = true;
                })
                .catch(function (e) { console.error(e); Message.dberror(e); })
                .finally(function () { self.isBusy = false; })
        } 
    }
    
    TransitionMod.prototype.addMissingConditionalFields = function (conditions, conditionalFields) {
        for (var i = 0; i < conditions.length; i++) {
            var cond = conditions[i];
            if (!conditionalFields.some((c) => c.key == cond.fieldId)) {
                conditionalFields.push({ typeId: cond.fieldTypeId, key: cond.fieldId, value: cond.fieldLabel });
            }
        }
        return conditionalFields;
    }

    TransitionMod.prototype.get_conditions = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.TRANSITION.GET_CONDITIONAL_TRANSITIONS;
        var setOperators = function () {
            self.operators = { "1": [], "2": [], "3": [], "0": ModuleDictionaries.dataSources.conditionalOperators.data }; //conditionalOperators
            if ((ModuleDictionaries.dataSources.conditionalOperators.data || []).length) {
                for (var i = 0; i < ModuleDictionaries.dataSources.conditionalOperators.data.length; i++) {
                    self.operators[ModuleDictionaries.dataSources.conditionalOperators.data[i].typeId].push(ModuleDictionaries.dataSources.conditionalOperators.data[i]);
                }
            }

            self[dataURL.method](dataURL, { url: { transitionId: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.stateInfo.moduleId } })
                .then(function (r) {

                    p.resolve(r);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                    Message.dberror(e);
                })            
        }

        if (!ModuleDictionaries.dataSources.forms.isLoaded)
            ModuleDictionaries.get_dataSource("conditionalOperators")
                .then(function () {
                    setOperators();
                })

        else setOperators();

        return p.promise;
    }

    TransitionMod.prototype.sync = function () {
        var self = this;
        var toSend = [], data = {};
        var dataURL = URI.TRANSITION.SYNC_ORDER_CONDITIONALS;
        if (self.conditionsList.length) {
            for (var i = 0; i < self.conditionsList.length; i++) {
                data.key = self.conditionsList[i].data.id;
                data.order = i;
                toSend.push(angular.copy(data));
            }
        }
        self.isBusy = true;

        self[dataURL.method](dataURL, { body: toSend }, { headers: { moduleId: self.stateInfo.moduleId } })
            .then(function (r) {
                Message.info("Order updated successfully");
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () { self.isBusy = false; });

    }

    TransitionMod.prototype.addCondition = function () {
        var self = this;
        var item = {
            id: null,
            transitionId: self.properties.id,
            stateId: null,
            fieldId: null,
            operatorId: null,
            value: "",
            value2: "",
            position: null
        };
        self.isBusy = true;
        self.conditionsList.splice(0, 0, self.createConditionForm(item));
    }

    TransitionMod.prototype.editCondition = function (item) {
        this.isBusy = true;
        item.editMode = true;
    }

    TransitionMod.prototype.saveCondition = function (item) {
        var self = this;
        item.validate();
        if (item.isValid) {
            item.loading = true;
            var dataURL = item.data.id ? URI.TRANSITION.EDIT_CONDITIONAL : URI.TRANSITION.ADD_CONDITIONAL;
            self[dataURL.method](dataURL, { body: item.data }, { headers: { moduleId: self.stateInfo.moduleId } })
                .then(() => Message.info("Condition saved successfully"))
                .then(() => {
                    ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
                    ModuleDictionaries.refreshDicts(['signatures', 'signatureTransitions']);
                })
                .then(function () {
                    self.properties.hasConditionalTransitions = true;
                    self.conditions();
                    self.isBusy = false;
                })
                .catch(function (e) {
                    // Message.dberror(e);
                    item.loading = false;
                    item.catch(e);
                })
                .finally(function () {
                    
                })
        }
    }

    TransitionMod.prototype.cancelCondition = function (item) {
        var self = this;
        if (!item.data.id) {
            self.conditionsList.splice(0, 1);
        } else {
            item.restore_Data();
            item.editMode = false;
            self.setGrid(item, item.data.operatorId == self.betweenOperatorId);
        }
        self.isBusy = false;
    }

    TransitionMod.prototype.deleteCondition = function (item) {
        var self = this;
        item.loading = true;
        var dataURL = URI.TRANSITION.DELETE_CONDITIONAL;
        self[dataURL.method](dataURL, { url: {id: item.data.id}, urltype: 'obj' }, { headers: { moduleId: self.stateInfo.moduleId } })
            .then(() => Message.info("Condition deleted successfully"))
            .then(() => {
                ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
                ModuleDictionaries.refreshDicts(['signatures', 'signatureTransitions']);
            })
            .then(function () {
                self.conditions();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                item.loading = false;
                self.isBusy = false;
            })
    }

    TransitionMod.prototype.get_FieldType = function (id) {
        var self = this;
        var type;
        for (var key in self.fieldsTypes) {
            if (self.fieldsTypes.hasOwnProperty(key)) {
                if (self.fieldsTypes[key].indexOf(id) != -1) {
                    type = key;
                    break;
                }
            }
        }
        return type;
    }

    TransitionMod.prototype.getFieldDatasource = function (dataSourceId) {
        var p = $q.defer();
        var self = this;
        var urlData = URI.FIELD_DEFINITION.DATASOURCE_LIST;
        var bodyParams = {
            id: dataSourceId,
            selectedIds: [],
            search: {
                criteria: "",
                isCurrent: false, page: 1, pagesize: MAX_NR_VISIBLE_SELECT_ITEMS
            }
        }

        self[urlData.method](urlData, { body: bodyParams }, { headers: { moduleId: userService.system.selectedModuleId } })
            .then(function (r) {
                p.resolve(r.data);
            }).catch(function (e) {
                Message.dberror(e);
                p.resolve([]);
            })

        return p.promise;
    }

    TransitionMod.prototype.get_FieldDataSourceId = function (id) {
        var self = this;
        return self.conditionFieldsDict?.find(o => o.key == id)?.dataSourceId;
    }

    TransitionMod.prototype.createConditionForm = function (item) {
        var self = this;
        var form = new Form(item);
        var x = self.get_FieldType(item.fieldId) || 0;
        if (item.value === undefined)
            item.value = null;
        //create form
        var isValueRequired = (op) => op && ![9, 10, 11, 12].includes(op); 
        var formContent = {
            fieldId: { label: 'If Field', type: 'select', options: self.conditionFieldsDict, validation: { required: true } },
            stateId: { label: 'Transition to state', type: 'select', options: ModuleDictionaries.dataSources.states.data, validation: { required: true } },
            operatorId: { label: 'Operator', type: 'select', options: item.id ? self.operators[x] : [], validation: { required: true } },
            value: { label: 'Value', type: item.dataSourceId ? 'select': 'text' },
            value2: { label: 'Second Value', type: item.dataSourceId ? 'select': 'text', visible: item.operatorId == self.betweenOperatorId },
        };
        if (item.dataSourceId) {
            formContent.value.options = item.options || [];
            formContent.value2.options = item.options || [];
        }
        if (isValueRequired(item.operatorId)) {
            formContent.value.validation = { required: true };
            if (item.operatorId == self.betweenOperatorId)
                formContent.value2.validation = { required: true };
        }
        else {
            formContent.value.editMode = false;
            formContent.value2.editMode = false;
        }
        if (item.id) form.editMode = false;
        form.set_Description(formContent);
        var onClose = function (field) {
            item.value = null;
            item.value2 = null;
            form.data.operatorId = null;
            form.set_Description(
                {
                    operatorId: {
                        label: 'Operator', type: 'select',
                        options: self.operators[self.get_FieldType(item.fieldId) || 0],
                        validation: { required: true },
                        onClose: function (field) {
                            operatorIdOnClose(field);
                            form.fieldsList.operatorId.onClose = this.onClose;
                        }
                    },
                    fieldId: {
                        label: 'If Field', type: 'select', 
                        options: self.conditionFieldsDict, 
                        validation: { required: true },
                        onClose: function (field) {
                            onClose(field);
                            form.fieldsList.fieldId.onClose = this.onClose;
                        }
                    }
                }, true);
            operatorIdOnClose();
        }
        form.fieldsList.fieldId.onClose = function (field) {
            onClose(field);
            form.fieldsList.fieldId.onClose = this.onClose;
        };
        form.fieldsList.operatorId.onClose = function (field) {
            operatorIdOnClose(field);
            form.fieldsList.operatorId.onClose = this.onClose;
        };
        var operatorIdOnClose = function (field) {
            item.value = null;
            item.value2 = null;
            var value2Needed = field && field._value && field._value == self.betweenOperatorId || false;
            var notEditable = !field || !isValueRequired(field._value);
            var dataSourceId = self.get_FieldDataSourceId(item.fieldId);
            if (dataSourceId) {
                if (item.options?.length) {
                    var fieldContent = {
                        value: { label: 'Value', type: 'select', options: item.options },
                        value2: { label: 'Second Value', type: 'select', options: item.options, visible: value2Needed },
                    };
                    if (isValueRequired(field?._value)) {
                        fieldContent.value.validation = { required: true };
                    }
                    if (value2Needed) {
                        fieldContent.value2.validation = { required: true };
                    }
                    form.set_Description(fieldContent, true);
                    form.fieldsList.value.editMode = !notEditable;
                    form.fieldsList.value2.editMode = !notEditable;
                    self.setGrid(form, value2Needed);
                } else {
                    self.getFieldDatasource(dataSourceId)
                        .then(function(data) {
                            // set options to not get them every time
                            item.options = data;

                            var fieldContent = {
                                value: { label: 'Value', type: 'select', options: data },
                                value2: { label: 'Second Value', type: 'select', options: data, visible: value2Needed },
                            };
                            if (isValueRequired(field?._value)) {
                                fieldContent.value.validation = { required: true };
                            }
                            if (value2Needed) {
                                fieldContent.value2.validation = { required: true };
                            }
                            form.set_Description(fieldContent, true);
                            form.fieldsList.value.editMode = !notEditable;
                            form.fieldsList.value2.editMode = !notEditable;
                            self.setGrid(form, value2Needed);
                        });
                }
            } else {
                if (notEditable) {
                form.set_Description(
                {
                    value: { label: 'Value', type: 'text' },
                    value2: { label: 'Second Value', type: 'text', visible: value2Needed }
                }, true);
                form.fieldsList.value.editMode = false;
                form.fieldsList.value2.editMode = false;
                self.setGrid(form, value2Needed);
                }
                else {
                    var fieldContent = {
                        value: { label: 'Value', type: 'text', validation: { required: true } }
                    };
                    if (value2Needed)
                        fieldContent.value2 = { label: 'Second Value', type: 'text', validation: { required: true }, visible: value2Needed };
                    else
                        fieldContent.value2 = { label: 'Second Value', type: 'text', visible: value2Needed };

                    form.set_Description(fieldContent, true);
                    form.fieldsList.value.editMode = true;
                    form.fieldsList.value2.editMode = true;
                    self.setGrid(form, value2Needed);
                }
            }
        }
        self.setGrid(form, item.operatorId == self.betweenOperatorId);
        form.store_Data();

        return form;
    }

    TransitionMod.prototype.setGrid = function (form, isBetween) {
        if (isBetween) {
            form.fieldsList.value2.visible = true;
            form.setTemplate('grid', [
                { fieldId: 20, operatorId: 20, value: 20, value2: 20, stateId: 20 }
            ]);
        }
        else {
            form.setTemplate('grid', [
                { fieldId: 25, operatorId: 25, value: 25, value2: 25, stateId: 25 }
            ]);
        }
    }

    TransitionMod.prototype.changeOrder = function (item, isDown) {
        var self = this;
        var i = self.conditionsList.indexOf(item);
        self.conditionsList.splice(i, 1);
        if (!isDown) self.conditionsList.splice(i - 1, 0, item);
        else self.conditionsList.splice(i + 1, 0, item);
        self.sync();
    }

    return TransitionMod;
});
