<mat-expansion-panel [expanded]="expanded" (opened)="itemOpened()">
    <mat-expansion-panel-header>
        <div class="header">
            <div class="avatar-user">
                <div class="avatar">
                    <mat-icon *ngIf="!data.logo">account_circle</mat-icon>
                    <img *ngIf="data.logo" src="{{ 'data:image/png;base64,' + data.logo }}" />
                </div>
                <div class="user">
                    <h4>{{ data.user }}</h4>
                    <div>{{ data.createdOn | itvDateTime }}</div>
                </div>
            </div>
            <div class="action-states">
                <div class="action">
                    <div class="box">
                        <div class="ellipsis" [title]="actionNameByType">{{ actionNameByType }}</div>
                        <div class="ellipsis" *ngIf="actionNameEmail" [title]="actionNameEmail">
                            {{ actionNameEmail }}
                        </div>
                    </div>
                </div>
                <div class="states" *ngIf="showStates">
                    <div
                        class="state ellipsis"
                        [style.background]="data.stateColor"
                        [style.color]="data.stateTextColor || '#fff'"
                        [title]="data.state">
                        {{ data.state }}
                    </div>
                    <div *ngIf="data.nextState">&raquo;</div>
                    <div
                        class="state ellipsis"
                        [style.background]="data.nextStateColor"
                        [style.color]="data.nextStateTextColor || '#fff'"
                        [title]="data.nextState">
                        {{ data.nextState }}
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <div class="content">
        <hr />
        <ng-content *ngIf="loaded"></ng-content>
        <div *ngIf="!loaded" class="loading">{{ "MODULE_INSTANCE_ACTIVITY.LOADING" | translate }}</div>
    </div>
</mat-expansion-panel>
