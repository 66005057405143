import { Component, EventEmitter, Input, OnInit, Output, effect, inject, signal } from '@angular/core';
import { SharedComponentsModule } from 'src/app/shared/modules/shared-components.module';
import { PermissionsService } from './permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterType, PeopleModel, PermissionsModel } from './permissions.model';
import { catchError, debounceTime, EMPTY, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormControl } from '@angular/forms';
import { DEBOUNCE_TIMES } from 'src/app/shared/consts';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ConfirmationDialog, ConfirmationDialogData } from 'src/app/shared/dialogs/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectPeopleDialogComponent } from '../select-people-dialog/select-people-dialog.component';
import { SelectPeopleParameters } from '../select-people-dialog/select-people-dialog.model';
import { Permission } from 'src/app/models/permission';
import { UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-permissions',
  standalone: true,
  providers: [PermissionsService],
  imports: [SharedComponentsModule],
  templateUrl: './permissions.component.html',
})
export class PermissionsComponent implements OnInit {
  translate = inject(TranslateService);
  userService = inject(UserService);
  permissionsService = inject(PermissionsService);
  notificate = inject(NotificationService);
  searchControl = new FormControl('');
  loading = signal(false);
  FILTER_TYPE = FilterType;
  currentFilterType = signal(FilterType.All);
  selectedPeopleId = signal<number>(null);

  @Input() entityInstanceId: number;
  @Input() rootModuleId: number;
  @Input() folderId?: number;
  @Input() fileId?: number;
  @Input() fileParentFolderId?: number;
  @Output() permissionChanged = new EventEmitter<PeopleModel[]>();

  readonly ContractModuleId: number = -1;
  notContractModule: boolean = true;
  availablePermission: PermissionsModel[] = [];
  peopleList: PeopleModel[] = [];
  initialPeopleList: PeopleModel[] = [];
  permissionsList: PermissionsModel[] = [];

  constructor(private dialog: MatDialog) {
    effect(() => this.currentFilterType() && this.search(), { allowSignalWrites: true });
  }

  ngOnInit(): void {
    this.notContractModule = this.rootModuleId != this.ContractModuleId;
    this.loading.set(true);
    this.searchControl.valueChanges.pipe(debounceTime(DEBOUNCE_TIMES.default)).subscribe(() => this.search());
    this.permissionsService
      .getAvaialblePermissions(this.entityInstanceId)
      .pipe(
        tap(
          permissions =>
            (this.availablePermission = permissions.map(
              p => ({ permissionId: p.id, permission: p.name }) as PermissionsModel,
            )),
        ),
        switchMap(() => this.getPeople()),
        tap(people => {
          this.mapPeople(people);
        }),
      )
      .subscribe({
        complete: () => this.loading.set(false),
        error: () => {
          this.loading.set(false);
        },
      });
  }

  getPeople(): Observable<PeopleModel[]> {
    return this.folderId
      ? this.permissionsService.getFolderPermissions(this.folderId, this.entityInstanceId)
      : this.permissionsService.getFilePermissions(this.fileId, this.entityInstanceId);
  }

  mapPeople(people: PeopleModel[]) {
    this.addMissingPermissions(people);
    this.peopleList = people;
    this.initialPeopleList = [...people];

    if (people?.length) {
      this.selectPeople(people[0]);
    } else {
      this.permissionsList = [];
    }
  }

  refreshPeop() {
    this.loading.set(true);
    return this.getPeople().pipe(
      map(people => {
        this.mapPeople(people);
      }),
      finalize(() => this.loading.set(false)),
    );
  }

  refreshPeople() {
    this.refreshPeop().subscribe();
  }

  changeFilter(filterType: FilterType) {
    this.currentFilterType.set(filterType);
  }

  search() {
    const criteria = this.searchControl.value;
    const filterType = this.currentFilterType();

    if (!criteria && filterType == FilterType.All) {
      this.peopleList = this.initialPeopleList;

      if (this.peopleList?.length) {
        this.selectPeople(this.peopleList[0]);
      } else {
        this.permissionsList = [];
      }

      return;
    }

    this.peopleList = this.initialPeopleList.filter(
      row =>
        (!criteria || (criteria && row.name.toString().toLowerCase().includes(criteria.toLowerCase()))) &&
        (filterType == FilterType.All ||
          (filterType == FilterType.Allow && row.permissions.some(p => p.allow)) ||
          (filterType == FilterType.Deny && row.permissions.some(p => p.deny))),
    );

    if (this.peopleList?.length) {
      this.selectPeople(this.peopleList[0]);
    } else {
      this.permissionsList = [];
    }
  }

  clearInput() {
    this.searchControl.reset();
  }

  addMissingPermissions(people: PeopleModel[]) {
    if (people?.length) {
      for (let i = 0; i < people.length; i++) {
        const item = people[i];
        if (item.permissions?.length != this.availablePermission.length) {
          for (let p = 0; p < this.availablePermission.length; p++) {
            if (!item.permissions.find(f => f.permissionId == this.availablePermission[p].permissionId)) {
              item.permissions.push({ ...this.availablePermission[p] });
            }
          }

          item.permissions = item.permissions.sort((a, b) => a.permissionId - b.permissionId);
        }
      }
    }
  }

  permissionValueChanged(permission: PermissionsModel, isAllow: boolean, event: MatCheckboxChange) {
    if (isAllow && event.checked) {
      permission.deny = false;
      permission.allow = true;
    } else if (!isAllow && event.checked) {
      permission.allow = false;
      permission.deny = true;
    } else {
      // if user uncheck allow or deny, then save valut to be saved in permissionsList
      permission.allow = false;
      permission.deny = false;
    }

    if (isAllow && permission.permissionId == Permission.Update) {
      var readPermission = this.permissionsList.find(p => p.permissionId == Permission.Read);
      if (readPermission) {
        // if user click on allow for update and deny is set for read, then remove deny from read
        if (event.checked && readPermission.deny) {
          readPermission.deny = false;
        }

        // If user click on allow update, automatically check read as allowed too
        if (event.checked) {
          readPermission.deny = false;
          readPermission.allow = true;
        }
      }
    }

    if (!isAllow && permission.permissionId == Permission.Read) {
      var updatePermission = this.permissionsList.find(p => p.permissionId == Permission.Update);
      if (updatePermission) {
        // if user click on deny for read and allow is set for update, then remove allow from update
        if (event.checked && updatePermission.allow) {
          updatePermission.allow = false;
        }
      }
    }

    this.permissionChanged.emit(this.peopleList);
  }

  selectPeople(item: PeopleModel) {
    this.permissionsList = item.permissions;
    this.selectedPeopleId.set(item.id);
  }

  deletePermissions(item: PeopleModel, event: MouseEvent) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'fileFolderPermissions.deleteDialog.title',
        messages: ['fileFolderPermissions.deleteDialog.message'],
      } as ConfirmationDialogData,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        if (item.isNew) {
          var index = this.peopleList.indexOf(item);
          this.peopleList.splice(index, 1);
          this.initialPeopleList = [...this.peopleList];

          // select the next row that was after what we deleted
          if (this.peopleList?.length) {
            this.selectPeople(this.peopleList[this.peopleList?.length == index ? index - 1 : index]);
          } else {
            this.permissionsList = [];
            this.permissionChanged.emit([]);
          }
        } else {
          this.permissionsService
            .deleteUserRolePermissions(
              this.folderId,
              this.fileId,
              item.isUser ? item.id : null,
              !item.isUser ? item.id : null,
              this.entityInstanceId,
            )
            .subscribe({
              complete: () => {
                this.loading.set(false);
                this.notificate.success(this.translate.instant('fileFolderPermissions.deleteDialog.success'));
                this.refreshPeop().subscribe(() => {
                  // if we deleted all people, then we need to do a refresh of project files
                  if (!this.peopleList?.length) {
                    this.permissionChanged.emit([]);
                  }
                });
              },
              error: () => {
                this.loading.set(false);
              },
            });
        }
      }
    });
  }

  openSettingModal() {
    const params = {
      folderId: this.folderId,
      fileId: this.fileId,
      fileParentFolderId: this.fileParentFolderId,
      entityInstanceId: this.entityInstanceId,
      notContractModule: this.notContractModule,
    } as SelectPeopleParameters;

    this.dialog
      .open(SelectPeopleDialogComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        ...this.userService.getResponsiveDialogSize().md,
        height: '65%',
        data: params,
      })
      .afterClosed()
      .subscribe((people: PeopleModel[]) => {
        this.addMissingPermissions(people);
        people = (people ?? []).filter(n => !this.peopleList.some(p => p.id === n.id && p.isUser === n.isUser));
        if (people?.length) {
          var initialLength = this.peopleList.length;
          this.peopleList = [...this.peopleList, ...people];
          this.initialPeopleList = [...this.peopleList];

          if (this.peopleList?.length) {
            this.selectPeople(this.peopleList[initialLength]);
          }

          this.permissionChanged.emit(this.peopleList);
        }
      });
  }

  getPeoplePermissionCode(people: PeopleModel) {
    return people.permissions.some(p => p.allow) && people.permissions.some(p => p.deny)
      ? 'AD'
      : people.permissions.some(p => p.allow) && people.permissions.some(p => !p.deny)
        ? 'A'
        : people.permissions.some(p => !p.allow) && people.permissions.some(p => p.deny)
          ? 'D'
          : 'N';
  }
}
