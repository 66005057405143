import { Component, computed, effect, input, model, signal } from '@angular/core';
import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';

import { FormControl } from '@angular/forms';
import { SearchableListBoxConfig, SearchableListItemType } from './searchable-listbox.model';
import { CipoFormModule } from '../../modules';
import { debounceTime } from 'rxjs';
import { DEBOUNCE_TIMES } from '../../consts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'cipo-searchable-listbox',
  templateUrl: './searchable-listbox.component.html',
  styleUrls: ['./searchable-listbox.component.scss'],
  standalone: true,
  imports: [TranslateModule, MatChipsModule, CipoFormModule, NgxSkeletonLoaderModule],
})
export class SearchableListboxComponent<TKey = number> {
  values = model<TKey[]>();
  options = input<SearchableListItemType<TKey>[]>();
  config = input<SearchableListBoxConfig, SearchableListBoxConfig>(null, {
    transform: config => {
      this.skeletonColumns.set(Array(config.columns || 1));
      return config;
    },
  });

  skeletonColumns = signal([]);
  skeleton = signal(Array(11));
  loading = signal<boolean>(false);
  searchControl = new FormControl('');
  searchValue = signal('');
  filteredOptions = computed(() => {
    const searchValue = this.searchValue();
    return this.options()?.filter(o => o.value?.toLowerCase()?.includes(searchValue?.toLowerCase()));
  });

  constructor() {
    effect(
      () => {
        const options = this.options();
        if (!options) {
          this.loading.set(true);
          return;
        }
        this.loading.set(false);
      },
      { allowSignalWrites: true },
    );

    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIMES.short))
      .subscribe(val => this.searchValue.set(val));
  }

  clearInput() {
    this.searchControl.setValue('');
  }

  selectionChanged({ value }: MatChipListboxChange) {
    this.values.set(value);
  }
}
