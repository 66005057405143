import { Component, EventEmitter, Input, Output, inject, signal } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';

import { OrderType, SearchViewModel } from '../../../models/module/grid';
import {
  DataListCommandFilterType,
  FiltersToApply,
  LocalFilterService,
} from '../../../shared/components/data-list/common';
import {
  CipoTableOptions,
  TablePaginator,
  TableRow,
} from '../../../shared/components/data-list/common/table.interface';
import { PAGE_SIZE_OPTIONS, PRESET_BG_COLOR, PRESET_COLOR } from './../../../shared/consts';
import { OpenDocumentsTableService } from './open-documents-table.service';

@Component({
  selector: 'app-open-documents-table',
  templateUrl: './open-documents-table.component.html',
})
export class OpenDocumentsTableComponent {
  localFilterService = inject(LocalFilterService);
  instanceId: number;
  searchOptions: SearchViewModel = {
    page: 1,
    pagesize: PAGE_SIZE_OPTIONS.long[2],
  };
  filters: DataListCommandFilterType[] = [];
  tableColumns = this.documentsService.getTableColumns();
  tableData = signal<TableRow[]>(undefined);
  tableOptions = signal<CipoTableOptions>({
    clickableRows: true,
    serverPaginationSort: true,
    pagination: {
      pageIndex: 0,
      pageSize: PAGE_SIZE_OPTIONS.long[2],
      pageSizeOptions: PAGE_SIZE_OPTIONS.long,
    },
  });

  isinfiniteScroll = signal(false);

  @Input('entityinstance')
  set _entityInstance(instance: any) {
    this.instanceId = instance.entityInstanceId;
    this.populateTableData();
    this.documentsService
      .getDocumentsModules(this.instanceId)
      .subscribe(modules => (this.filters = this.documentsService.initFilters(modules)));
  }
  @Output('openpopupevent') openPopupEvent: EventEmitter<any> = new EventEmitter<any>();

  openPopup(element: any) {
    this.openPopupEvent.emit(element);
  }

  constructor(
    private documentsService: OpenDocumentsTableService,
    private translate: TranslateService,
  ) {
    // for experimental purposes. Will be get from Store later
    if (localStorage.getItem('INFINITE_SCROLL') === '1') {
      // If it's infinite scroll, we will get 50 items per page
      this.tableOptions.update(options => {
        options.pagination.pageSize = PAGE_SIZE_OPTIONS.default[3];
        return options;
      });
      this.searchOptions.pagesize = PAGE_SIZE_OPTIONS.default[3];
      this.isinfiniteScroll.set(true);
    }
  }

  populateTableData() {
    this.documentsService
      .getOpenDocuments(this.searchOptions, this.instanceId)
      .pipe(
        filter(data => !!data),
        map(({ data, records }) => ({
          records,
          data: data.map(row => {
            const tableRow: TableRow = {
              ...row,
              state: {
                bgColor: row.isInApproval ? PRESET_BG_COLOR : row.stateColor,
                color: row.isInApproval ? PRESET_COLOR : row.stateTextColor,
                label: row.isInApproval ? this.translate.instant('defaultStatus.InApproval') : row.state,
              },
            };
            return tableRow;
          }),
        })),
      )
      .subscribe(({ data, records }) => {
        this.tableData.set(data);
        this.tableOptions.update(options => {
          options.pagination.pageIndex = this.searchOptions.page - 1;
          options.pagination.length = records;
          return { ...options };
        });
      });
  }

  search({ filters, criteria }: FiltersToApply) {
    this.searchOptions.criteria = criteria;
    this.searchOptions.filter = this.localFilterService.mapLocalFilters(filters);
    this.searchOptions.page = 1;
    this.tableData.set(null);
    this.tableOptions.update(options => {
      options.pagination.pageIndex = 0;
      return options;
    });

    this.populateTableData();
  }

  handleSort(event: Sort) {
    this.searchOptions.sort = [
      {
        field: event.active,
        order: event.direction === 'asc' ? OrderType.Ascending : OrderType.Descending,
      },
    ];

    this.populateTableData();
  }

  handlePagination({ pageIndex, pageSize }: TablePaginator) {
    this.searchOptions = {
      ...this.searchOptions,
      page: pageIndex + 1,
      pagesize: pageSize,
    };

    this.populateTableData();
  }
}
