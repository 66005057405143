import { ChangeDetectionStrategy, Component, computed, signal, inject, effect, untracked } from '@angular/core';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { toSignal } from '@angular/core/rxjs-interop';

import { SharedComponentsModule } from '../../../modules/shared-components.module';
import { DataListScrollStrategy, TableRow } from '../common';
import { DataListBase } from '../data-list.base';
import { TableCellComponent } from '../table-cell/table-cell.component';
import { PAGE_SIZE_OPTIONS } from '../../../consts';

// we might want this to be configurable, but better not :)
const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 34;
const BUFFER_SIZE = 5;
const ITEMS_PER_PAGE = 30;

@Component({
  selector: 'cipo-infinite-data-list',
  standalone: true,
  imports: [SharedComponentsModule, TableCellComponent],
  templateUrl: './infinite-data-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useClass: DataListScrollStrategy,
    },
  ],
})
export class InfiniteDatalistComponent<T = TableRow> extends DataListBase<T> {
  virtualScrollStrategy = inject<DataListScrollStrategy>(VIRTUAL_SCROLL_STRATEGY);

  rows = signal<T[]>([]);
  staticRows: T[] = [];
  lastLoadedPageIndex = 0;

  scrollChanged = toSignal(this.virtualScrollStrategy.scrolledIndexChange);

  infiniteDataSource = computed(() => {
    const rows = this.rows();
    const loading = untracked(this.loading);
    const pagination = untracked(this.options).pagination;
    if (rows.length === 0 && loading) {
      return new MatTableDataSource(Array.from({ length: pagination.pageSize }, () => ({ empty: true }) as T));
    }
    const index = this.scrollChanged();
    const start = Math.max(0, (index || 0) - BUFFER_SIZE);
    const end = Math.min(rows.length, start + ITEMS_PER_PAGE + BUFFER_SIZE);
    const ds = rows.slice(start, end);
    const nextPage = pagination.pageIndex + 1;

    if (rows.length === end && this.lastLoadedPageIndex !== nextPage && rows.length < pagination.length) {
      this.lastLoadedPageIndex = nextPage;
      this.paginationUpdated.emit({
        pageIndex: nextPage,
        pageSize: PAGE_SIZE_OPTIONS.default[3],
      });
    }
    if ((rows.length === end && rows.length < pagination.length) || loading) {
      ds.push({ empty: true } as T);
    }
    return new MatTableDataSource(ds);
  });

  constructor() {
    super();
    effect(
      () => {
        const tableData = this.tableData();
        const options = this.options();
        if (!tableData) {
          this.loading.set(true);
          this.rows.set([]);
          return;
        }
        this.rows.update(arr => (options.pagination.pageIndex === 0 ? [...tableData] : arr.concat(tableData)));
        this.staticRows.push(...tableData);
        this.checkLeftRightIcons(tableData);
        this.loading.set(false);
      },
      { allowSignalWrites: true },
    );
    this.virtualScrollStrategy.setScrollHeight(ROW_HEIGHT, HEADER_HEIGHT);
  }

  tableSortChanged(event: Sort) {
    if (this.options()?.serverPaginationSort) {
      this.sortChanged.emit(event);
    } else {
      this.rows.update(() => {
        if (event.direction === 'asc') {
          return [...this.staticRows].sort((a, b) => (a[event.active] > b[event.active] ? 1 : -1));
        }
        if (event.direction === 'desc') {
          return [...this.staticRows].sort((a, b) => (a[event.active] < b[event.active] ? 1 : -1));
        }
        return [...this.staticRows];
      });
    }
  }

  isAllSelected() {
    const numSelected = this.selection().selected.length;
    const numRows = this.rows().length;
    return numSelected === numRows;
  }

  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection().clear();
      return;
    }
    this.selection().select(...this.rows());
  }
}
