import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap, tap } from 'rxjs';

import { CipoTableOptions, TableColumn } from '../../../shared/components/data-list/common';
import { PAGE_SIZE_OPTIONS } from '../../../shared/consts';
import { NotificationService } from '../../../shared/services/notification.service';
import { TenantSettingModalComponent } from './tenant-setting-modal/tenant-setting-modal.component';
import {
  TenantSettingListIds,
  TenantSettingListValues,
  TenantSettingRow,
  TenantSettingsEnum,
  TenantSettingType,
  TenantSettingValue,
} from './tenant-settings.model';
import { TenantSettingsService } from './tenant-settings.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { tenantActions } from '../../../store/tenant';

@Component({
  selector: 'app-tenant-settings',
  templateUrl: './tenant-settings.component.html',
})
export class TenantSettingsComponent implements OnInit {
  translate = inject(TranslateService);
  dialog = inject(MatDialog);
  notificate = inject(NotificationService);
  tenantSettingsService = inject(TenantSettingsService);
  store = inject(Store<AppState>);
  loading = true;
  criteria = '';

  tableOptions: CipoTableOptions = {
    clickableRows: true,
    pagination: {
      pageSize: PAGE_SIZE_OPTIONS.long[2],
      pageSizeOptions: PAGE_SIZE_OPTIONS.long,
    },
    sort: {
      active: 'name',
      direction: 'asc',
    },
  };
  tableColumns: TableColumn[];
  tableData: TenantSettingRow[] = [];
  initialTableData: TenantSettingRow[] = [];
  canUpdate = signal(false);

  @Input('canupdate')
  set _canUpdate(value: boolean) {
    this.canUpdate.set(value);
  }

  constructor() {
    this.tenantSettingsService.getColumns().subscribe(columns => (this.tableColumns = columns));
  }

  ngOnInit(): void {
    this.getMappedData();
  }

  getMappedData() {
    this.loading = true;
    this.tenantSettingsService
      .getListsValues()
      .pipe(switchMap(() => this.getTenantSettingsMapped()))
      .subscribe(() => (this.loading = false));
  }

  getTenantSettingsMapped() {
    return this.tenantSettingsService.getTenantSettings().pipe(
      map(settings => {
        this.store.dispatch(tenantActions.set({ tenant: { settings } }));
        return settings.map(({ value, type, ...args }) => {
          const val = this.translateValue(args.id, type, value);
          return {
            ...args,
            type,
            rawValue: value,
            value: val,
          };
        });
      }),
      tap(settings => {
        this.initialTableData = settings;
        this.search(this.criteria);
      }),
    );
  }

  search(criteria: string) {
    this.criteria = criteria;
    if (!criteria) {
      this.tableData = this.initialTableData;
      return;
    }
    this.tableData = this.initialTableData.filter(row => {
      return Object.keys(row).some(key => {
        return (row[key] || '').toString().toLowerCase().includes(criteria.toLowerCase());
      });
    });
  }

  openSettingModal(tenantSetting: TenantSettingRow) {
    if (!this.canUpdate()) {
      return;
    }
    this.dialog
      .open(TenantSettingModalComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        width: '500px',
        data: tenantSetting,
      })
      .afterClosed()
      .pipe(
        filter(value => value !== undefined),
        switchMap(value => this.tenantSettingsService.updateSetting({ ...tenantSetting, value })),
      )
      .subscribe(value => {
        this.tableData = this.tableData.map(row => {
          if (row.id === tenantSetting.id) {
            row.rawValue = value;
            row.value = this.translateValue(row.id, row.type, value);
            this.store.dispatch(tenantActions.updateValue({ id: row.id, value: row.value }));
          }
          return row;
        });
        this.notificate.success(this.translate.instant('tenantSetting.settingUpdated'));
      });
  }

  private translateValue(id: TenantSettingsEnum, type: TenantSettingType, value: TenantSettingValue) {
    if (type === 'boolean') {
      return this.translate.instant('general.boolValue.' + value);
    } else if (Array.from(TenantSettingListIds.keys()).includes(id)) {
      return TenantSettingListValues.get(id).find(o => o.key?.toString() === value?.toString())?.value;
    } else if (id === TenantSettingsEnum.load_more) {
      return this.translate.instant('tenantSetting.loadMore.' + value);
    }
    return value;
  }
}
